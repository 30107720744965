import './Button.scss'

const Button = props => {

  function css() {
    if (props.primary)
      return 'btn btn-primary'
    if (props.secondary)
      return 'btn btn-secondary'
    if (props.member)
      return 'btn btn-member'
    if (props.header)
      return 'btn btn-header'
    return 'btn'
  }

  const buttonElement = (
      <div className={css()} onClick={props.onClick}>
        {props.children}
      </div>
  );

  if (props.href)
    return <a href={props.href} style={{ textDecoration: 'none'}}
              rel="noreferrer" target="_blank">
      {buttonElement}
    </a>

  return buttonElement
};

export default Button;
