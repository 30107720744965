import './App.scss';
import Button from "./components/Button/Button";
import logo from "./images/chi3-logo.png"
import { ReactComponent as Twitter } from "./images/twitter.svg";
import { ReactComponent as Discord } from "./images/discord.svg";

const discord = "https://discord.gg/aEEYVMPrEh"
const twitter = "https://twitter.com/Chi3studios"
const airtable = "https://airtable.com/embed/appvoM76Cos0qKGst/shrYvvurdQBNKoXwH?backgroundColor=blue"
const googleMaps = "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJcaz1arAsDogR4dOyAlEDlns&key=AIzaSyAuNUrv9Ogp8uxDIPn207eNX_6jf8Kstd0"

function App() {

  function scroll() {
    const element = document.getElementById('join');
    const headerOffset = 150;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  return (
    <div className="App">
      <div className="row sticky-header">
        <header className="header">
          <div className="wrapper-logo" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
            <img src={logo} alt="chi3 logo" />
            <h1 className="logo text-md">
              CHI 3
            </h1>
          </div>
          <Button header href={discord}>
            Let's chat
          </Button>
        </header>
      </div>
      <main className="row">
        <h1 className="logo text-big">CHI 3</h1>
        <h2 className="subtitle">Chicago's Web3<br/> Studio</h2>
        <div className="buttons-wrapper">
          <Button primary onClick={scroll}>
            Join Now
          </Button>
          <Button secondary href={discord}>
            Learn More
          </Button>
        </div>
      </main>
      <div className="section-one row">
        <div className="section-wrapper">
          <div className="left">
            <h1 className="title">Chicago's Premier web3 Coworking location</h1>
          </div>
          <div className="right">
            <h2 className="text">Meet fellow builders in a next-gen web3 studio.</h2>
            <h2 className="text">Join special speaker events, hackathons, mentorship programs & VC Pitch days.</h2>
            <h2 className="text">Apply for opportunities to receive grants to grow your projects.</h2>
            <Button member onClick={scroll}>Become a Member</Button>
          </div>
        </div>
      </div>
      <footer className="row">
        <h1 id="join">Join Now</h1>
        <iframe title="airtable" className="airtable-embed"
                width="100%" height="1060px"
                style={{background: 'transparent', maxWidth: '100vw', border: 'none', overflow: 'hidden'}}
                src={airtable}
        ></iframe>
        <h1 style={{ marginTop: 0 }}>Location</h1>
        <iframe title="map" loading="lazy" allowFullScreen
                width="600" height="450"
                style={{ maxWidth: '90vw', maxHeight: '60vh', border: 'none' }}
                src={googleMaps}
        ></iframe>
        <div style={{ margin: '30px auto'}}>
          <div className="footer-svgs">
            <a href={twitter} rel="noreferrer" target="_blank" style={{ textDecoration: 'none'}}>
              <Twitter height={30} width={30} />
            </a>
            <a href={discord} rel="noreferrer" target="_blank" style={{ textDecoration: 'none'}}>
              <Discord height={30} width={30} />
            </a>
          </div>
          <div style={{ marginTop: 20}}>
            55 w Wacker Drive, Chicago, IL, 60601
          </div>
          <div style={{ marginTop: 10, opacity: 0.5, fontSize: 15}}>
            © Chi3 Studio {new Date().getFullYear()}. All rights reserved
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
